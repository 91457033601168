import React, { Component } from 'react';
import "./Offer_01.css";
import "./Offer_07.css";
import { Link } from "react-scroll";
import Fade from 'react-reveal/Fade';
import Gift from "./gift.png";
import Head from "./head.png";
import Compass from "./compass.png";
import Pin from "./pin.png";
import Watch from "./watch.png";
import People from "./people.png";
import Person1 from "./personTester.png";
import Person2 from "./person2.png";
import Logo from "./logo.png";
// import GA from './utils/GoogleAnalyticsJavaDeveloper';
import Banner from './Banner';

const positionName = "Manual Testing Engineer";

class Offer07 extends Component {

    state = {
        isExtraVisible: false,
        applied: false,
        lang: 'eng'
    }

    handleExtra = () => {
        this.setState({ isExtraVisible: !this.state.isExtraVisible });
        console.log(this.state.isExtraVisible);

    }

    handleScroll = e => {

        const body = document.body,
            html = document.documentElement;

        const documentHeight = Math.max(body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight);

        const currentScrollValue = document.documentElement.scrollTop;

        const applyBanner = document.getElementById("applyBanner");

        if (currentScrollValue < .65 * documentHeight) {
            applyBanner.classList.remove("invisible");
        } else {
            applyBanner.classList.add("invisible");
        }

    }

    closeBanner = () => {
        this.setState({ applied: false });
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        window.scrollTo(0, 0);
        document.title = "Accenture | " + positionName;

        if (this.props.location.search.includes("success=true")) {
            this.setState({ applied: true });
        }
        if (this.props.location.search.includes("lang=pl")) {
            this.setState({ lang: 'pl' });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    render() {
        return (
            <>
                {/* {GA.init() && <GA.RouteTracker />} */}
                <div className="offer offer07">
                    {this.state.applied && <Banner close={this.closeBanner} lang={this.state.lang} />}

                    <div className="offer__apply offer__applyA" id="applyBanner">
                        <div className="section__wrap">
                            <img className="offer__image" src={Logo} alt="Accenture Logo" />
                            <Link to="contact" spy={true} smooth={true} offset={0} duration={500}>
                                <span href="#contact"><button className="offer__button">Zaaplikuj</button></span>
                            </Link>
                        </div>

                    </div>

                    <div className="offer__header">
                        <div className="offer__titles offer__wrap">
                            <img className="offer__person1" src={Person1} alt="person" />
                            {/* <img className="offer__image" src={Sign} alt="Accenture Logo" /> */}
                            {/* <Fade up> */}
                            <h1 className="offer__title">MANUAL</h1>
                            <h1 className="offer__title">TESTING</h1>
                            <h1 className="offer__title">ENGINEER</h1>
                            {/* </Fade> */}
                            <div className="header__info">
                                <div className="row">
                                    <Fade left>
                                        <img src={Pin} alt="pin sign" />
                                    </Fade>
                                    <p>Łódź</p>
                                </div>
                                <div className="row">
                                    <Fade left>
                                        <img src={Watch} alt="watch sign" />
                                    </Fade>
                                    <p>Pełny etat</p>
                                </div>
                                <div className="row">
                                    <Fade left>
                                        <img src={People} alt="people sign" />
                                    </Fade>
                                    <p>Mid/Ekspert</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="section offer__benefits">
                        <div className="section__wrap">
                            <div className="section__content">
                                {/* <Fade down> */}
                                <h2 className="offer__subtitle">Korzyści, które otrzymasz</h2>
                                {/* </Fade> */}
                                <div className="section__table">
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Jasno zdefiniowana ścieżka kariery</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Vouchery lunchowe</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Bilety sportowe</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Wewnętrzne szkolenia, warsztaty, kursy</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Atrakcyjne możliwości rozwoju kariery wewnątrz organizacji</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Dodatkowe ubezpieczenie na życie</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Międzynarodowe projekty</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Cykliczne spotkania integracyjne</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Prywatna opieka medyczna</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Korporacyjna karta kredytowa American Express Gold</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Mentoring</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Dostęp do platformy e-learning</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Certyfikacje</div>
                                    {/* </Fade> */}
                                    {/* <Fade right> */}
                                    <div className="section__row-text">Dedykowany budżet szkoleniowy</div>
                                    {/* </Fade> */}
                                </div>
                            </div>
                            <Fade right>
                                <div className="section__image-wrap">
                                    <img className="section__image" src={Gift} alt="Gift icon" />
                                </div>
                            </Fade>
                        </div>
                    </section>

                    <section className="section offer__details">
                        <div className="section__wrap">
                            <img className="offer__person2" src={Person2} alt="person" />
                            <div className="offer__subtitles">
                                {/* <Fade left> */}
                                <h2 className="offer__subtitle">Let's improve</h2>
                                {/* </Fade> */}
                                {/* <Fade left> */}
                                <h2 className="offer__subtitle">the world</h2>
                                {/* </Fade> */}
                                {/* <Fade left> */}
                                <h2 className="offer__subtitle green">together</h2>
                                {/* </Fade> */}
                            </div>
                        </div>
                    </section>

                    <section className="section offer__tasks">
                        <div className="section__wrap">
                            <div className="section__content">
                                {/* <Fade down> */}
                                <div className="offer__subtitles">
                                    <h2 className="offer__subtitle">Twoje główne zadania</h2>
                                </div>
                                {/* </Fade> */}
                                <div className="section__table">
                                    <div className="section__row">
                                        <Fade left>
                                            <div className="section__row-image"><p>1</p></div>
                                        </Fade>
                                        {/* <Fade left> */}
                                        <div className="section__row-text">Testowanie systemów webowych oraz desktopowych w ramach projektów dla międzynarodowych klientów</div>
                                        {/* </Fade> */}
                                    </div>
                                    <div className="section__row">
                                        <Fade left>
                                            <div className="section__row-image">2</div>
                                        </Fade>
                                        {/* <Fade left> */}
                                        <div className="section__row-text">Przygotowywanie planów testów</div>
                                        {/* </Fade> */}
                                    </div>
                                    <div className="section__row">
                                        <Fade left>
                                            <div className="section__row-image">3</div>
                                        </Fade>
                                        {/* <Fade left> */}
                                        <div className="section__row-text">Tworzenie scenariuszy testowych</div>
                                        {/* </Fade> */}
                                    </div>
                                    <div className="section__row">
                                        <Fade left>
                                            <div className="section__row-image">4</div>
                                        </Fade>
                                        {/* <Fade left> */}
                                        <div className="section__row-text">Przeprowadzanie testów manualnych – od przygotowania środowiska do wdrożenia testu i raportowania wyników</div>
                                        {/* </Fade> */}
                                    </div>
                                    <div className="section__row">
                                        <Fade left>
                                            <div className="section__row-image">5</div>
                                        </Fade>
                                        {/* <Fade left> */}
                                        <div className="section__row-text">Zgłaszanie błędów do odpowiednich zespołów programistycznych</div>
                                        {/* </Fade> */}
                                    </div>
                                    <div className="section__row">
                                        <Fade left>
                                            <div className="section__row-image">6</div>
                                        </Fade>
                                        {/* <Fade left> */}
                                        <div className="section__row-text">Raportowanie i opisywaniu wyników testów</div>
                                        {/* </Fade> */}
                                    </div>
                                </div>
                            </div>
                            <Fade right>
                                <div className="section__image-wrap">
                                    <img className="section__image" src={Compass} alt="Compass icon" />
                                </div>
                            </Fade>
                        </div>
                    </section>

                    <section className="section offer__expect">
                        <div className="section__wrap">
                            <div className="section__content">
                                {/* <Fade down> */}
                                <h2 className="offer__subtitle">Umiejętności, których poszukujemy</h2>
                                {/* </Fade> */}
                                <div className="must-have">
                                    {/* <Fade left> */}
                                    <h3>Wymagane</h3>
                                    {/* </Fade> */}
                                    {/* <Fade left> */}
                                    <div className="features">
                                        <p className="feature">Testing</p>
                                        <p className="feature">Jira</p>
                                        <p className="feature">HP ALM</p>
                                        <p className="feature">analityczne myślenie</p>
                                        <p className="feature">SQL</p>
                                    </div>
                                    {/* </Fade> */}
                                </div>
                                <div className="must-have">
                                    {/* <Fade left> */}
                                    <h3>Mile widziane</h3>
                                    {/* </Fade> */}
                                    {/* <Fade left> */}
                                    <div className="features">
                                        <p className="feature">JAVA</p>
                                        <p className="feature">Python</p>
                                        <p className="feature">continuous integration</p>
                                        <p className="feature">angielski</p>
                                    </div>
                                    {/* </Fade> */}
                                </div>
                            </div>
                            <Fade right>
                                <div className="section__image-wrap">
                                    <img className="section__image" src={Head} alt="Head icon" />
                                </div>
                            </Fade>
                        </div>
                    </section>

                    <section className="section offer__about">
                        <div className="section__wrap">
                            <div className="section__video">
                                <iframe src="https://www.youtube.com/embed/ux7Ef5-0g5A?autoplay=0&mute=0&controls=0&origin=https%3A%2F%2Fworkate.pl&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1" frameBorder="0" scrolling="no" title="About Accenture"></iframe>
                            </div>
                        </div>
                    </section>

                    <section className="section offer__contact" id="contact">
                        <div className="section__wrap">
                            <div className="offer__subtitles">
                                {/* <Fade down> */}
                                <h2 className="offer__subtitle">Dołącz do nas!</h2>
                                {/* </Fade> */}
                                {/* <Fade down> */}
                                <h2 className="offer__subtitle green">Zaaplikuj!</h2>
                                {/* </Fade> */}
                            </div>
                            <div className="form__wrap">
                                <form className="form" id="formOfferA" method="POST" action="index.php">
                                    <input type="hidden" name="offer" value={positionName} />
                                    {/* <Fade up> */}
                                    <input placeholder="Imię" name="firstName" required />
                                    {/* </Fade> */}
                                    {/* <Fade up> */}
                                    <input placeholder="Nazwisko" name="lastName" required />
                                    {/* </Fade> */}
                                    {/* <Fade up> */}
                                    <input type="email" placeholder="Email" name="email" required />
                                    {/* </Fade> */}
                                    {/* <Fade up> */}
                                    <input type="number" placeholder="Numer telefonu" name="phoneNumber" required />
                                    {/* </Fade> */}
                                    <input type="hidden" name="type" value="-" />
                                    {/* <Fade up> */}
                                    <select name="city" id="city" defaultValue={"DEFAULT"}>
                                        <option value="DEFAULT" disabled>Miasto</option>
                                        <option value="Łódź">Łódź</option>
                                    </select>
                                    {/* </Fade> */}
                                    <input type="hidden" name="profile" value="-" />
                                    {/* <Fade up> */}
                                    <label className="firstLabel">
                                        <input type="checkbox" required />
                                        <span>Wyrażam zgodę na przetwarzanie moich danych osobowych dla celów rekrutacji Accenture. <span className={this.state.isExtraVisible ? "extra visible" : "extra"}>Administratorem danych osobowych jest Accenture Sp. z o.o. (z siedzibą w Warszawie, przy ulicy Siennej 39, 00-121), w którego imieniu dane osobowe przetwarza jako podmiot przetwarzający Higher Sp. z o.o. (z siedzibą w Warszawie, przy ul. Krakowskie Przedmieście 79/300, 00-079), zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/2017 z dnia 27 kwietnia 2016 r. (ogólne rozporządzenie o ochronie danych) oraz ustawą z dnia 10 maja 2018 r. o ochronie danych osobowych (Dz. U. z 2018 r., poz. 1000 z późn. zm.). Jestem świadomy, że dane będą wykorzystywane do celów rekrutacji. Podanie danych jest dobrowolne, ale niezbędne do wzięcia udziału w rekrutacji. Osobie, której dane dotyczą, przysługuje prawo do wycofania zgody lub sprzeciwu wobec przetwarzania danych, żądania dostępu do nich, sprostowania, usunięcia, ograniczenia przetwarzania i ich przenoszenia, w dowolnym momencie oraz prawa do wniesienia skargi do organu nadzorczego.</span></span>

                                        {/* <span>I hereby consent to the processing of my personal data by Accenture sp. z o.o. with its registered seat in Warsaw (00-121), at ul. Sienna 39, NIP 526-00-15-900 and Higher Sp. z o.o. with its registered seat in Warsaw (00-079), at ul. Krakowskie Przedmieście 79/300 (Data Controllers), in accordance with the Act of August 29, 1997 on the Protection of Personal Data (Journal of Laws of 2016, item 922)<span className={this.state.isExtraVisible ? "extra visible" : "extra"}> and the Regulation on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (Data Protection Directive), necessary to carry out the recruitment process by Accenture. At the same time, I declare that I provide my personal data completely voluntary. I also declare that I have been informed about my right to withdraw my consent or object to processing of data, request access to them, rectification, deletion, limitation of processing and their transfer, at any time and the right to lodge a complaint to the data protection supervisory authority</span>.</span> */}
                                    </label>
                                    <div className="extra-toggler" onClick={this.handleExtra}>{this.state.isExtraVisible ? "Pokaż mniej" : "Pokaż więcej"}</div>
                                    {/* </Fade> */}
                                    {/* <Fade up> */}
                                    <label>
                                        <input type="checkbox" required />
                                        <span>Zapoznałem się z <a href="https://accenture.higher.pl/polityka-prywatnosci?fbclid=IwAR0-YnRLr9nGefqotOTOW7tC2KAfyUkEQGyJwk3asLuCK6vR7VGGicKVemc" target="_blank" rel="noopener noreferrer">Regulaminem oraz Polityką Prywatności</a></span> i akceptuję je.
                                        </label>
                                    {/* </Fade> */}
                                    {/* <Fade up> */}
                                    <input className="offer__button" id="send_application" name="submit_offer_new" type="submit" value="Wyślij" />
                                    {/* </Fade> */}
                                </form>
                                <div className="image__wrap"></div>
                            </div>
                        </div>
                    </section>

                    <footer className="offer__footer">
                        <div className="section__wrap">
                            <div className="footer__socials">
                                <a href="https://www.linkedin.com/company/accenturepoland?trk=vsrp_companies_cluster_name&trkInfo=VSRPsearchId%3A220393721475495038518%2CVSRPtargetId%3A15154437%2CVSRPcmpt%3Acompanies_cluster?" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
                                <a href="https://m.facebook.com/AccentureKariera/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i>
                                </a>
                                <a href="https://twitter.com/Accenture" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i>
                                </a>
                                <a href="https://m.youtube.com/user/AccenturePoland" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
                            </div>
                        </div>
                    </footer>
                </div>
            </>
        );
    }

}

export default Offer07;